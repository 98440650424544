import "bootstrap/scss/bootstrap.scss";
import "bootstrap-icons/font/bootstrap-icons.css";
import '@fontsource/open-sans';
import '../sass/common.scss';
import '../sass/home.scss';

import * as React from "react";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Row from 'react-bootstrap/Row';
import Carousel from 'react-bootstrap/Carousel';
import { StaticImage } from 'gatsby-plugin-image'

import PageTemplate from '../components/page-template';

const IndexPage = () => {
  return (
    <PageTemplate className="d-grid gap-6 mb-5">
      <Row className="hero">
        <Col className="hero-image-container" sm={6} xs={12}>
          <StaticImage
            alt="happy woman eating"
            src="../images/eating.jpg"
          />
        </Col>
        <Col className="hero-container" sm={6} xs={12}>
          <div>
            <h1 className="large-text">Feel relaxed around food, restore trust with your body & feel good in your skin</h1>
            <Button rel="noreferrer" className="hero-btn" href="https://calendly.com/polina_fiksson_nutrition/discovery" target="_blank">
              Book a FREE Discovery Call Now
            </Button>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <h3>ABOUT ME</h3>
          <p className="large-text">Hi, I'm Polina</p>
          <p>I'm a Certified Coach in Mind Body Nutrition and Dynamic Eating Psychology.  I work with women tired of obsessing over food and body size.</p>
          <p>My aim is to support you to restore trust with your body, feel comfortable in your own skin, and have a stress-free relationship with food.</p>
          <p>I do this through a combination of cutting-edge strategies from eating psychology and nutrition science and focusing on what’s right for your unique body.</p>
          <Button href="/about" className="home-secondary-btn" variant="outline-secondary">Learn More About Me</Button>
        </Col>
        <Col>
          <StaticImage
            alt="image of Polina Fiksson"
            src="../images/polina.png"
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <StaticImage
            alt="Cup of tea on sofa"
            src="../images/coaching.png"
          />
        </Col>
        <Col>
          <h3>ABOUT MY COACHING</h3>
          <ul title="1:1 Coaching with me is:">
            <li><i class="bi bi-check"></i> eliminating all the "shoulds and shouldn’ts"</li>
            <li><i class="bi bi-check"></i> personalized and creative approach that works for your unique body and lifestyle</li>
            <li><i class="bi bi-check"></i> support through every step of your journey to change your relationship with food</li>
            <li><i class="bi bi-check"></i> exploring some of the personal dimensions in life that impact food, body image and health</li>
          </ul>
        </Col>
      </Row>
      <Row className="my-5 carousel-container">
        <Carousel fade variant="dark" nextLabel="next testimonial" prevLabel="previous testimonial">
          <Carousel.Item>
            <Carousel.Caption>
              <p className="fw-bold fst-italic mb-4">
               "Polina helped me reframe my mindset around food (remove focus from unproductive, destructive thoughts), expand my view on the "issue" and understand that food is not my enemy. That, as it turns out, I don’t have to stop going out with friends ("There’s food everywhere - I can't eat it, but they can"), that there are no good or bad foods, and that food is not always the best way to solve problems.
               Polina approached my case in a very individualized way. She provided me with a lot of tools, interesting homework assignments and used different approaches to support me with the challenges. She played a role of a nutritionist, a psychologist, and a friend all along."
              </p>
              <p> - Margaret </p>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <Carousel.Caption>
              <p className="fw-bold fst-italic mb-4">
                "I used to be confused about a lot of conflicting information about nutrition and was struggling with overeating and emotional eating for a long time. After working with Polina I became more confident about listening to my body, recognized the effects of mindless eating and emotional eating. I was able to shift the priority from weight loss to working on habits and health and learned to eat in a more balanced way. But also I no longer fear allowing myself to have fun foods more often. I feel that what I am working on right now will be more sustainable and last longer than any diet."
              </p>
              <p> - Anhelina </p>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <Carousel.Caption>
              <p className="fw-bold fst-italic mb-4">
                "Working with Polina opened my eyes on many things and helped me adjust my nutrition. Among other qualities Polina is very sensitive, kind and attentive! It's very easy and pleasant to work with her. I will continue to practice all the tools that I learned to help my body recover. I know for sure I'll return to Polina more than once for help. Thank you very much for all your help and support!"
              </p>
              <p> - Elina </p>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <Carousel.Caption>
              <p className="fw-bold fst-italic mb-4">
                "I tried many things in terms of nutrition and weight loss, but couldn’t get any significant or long-term results. Polina helped me make several obvious, but very important "discoveries". I always thought that I was just lazy, but through our work together, I realized that the main reason for my attempts not being successful is the high level of stress in my life. I realized that it's not my fault and I stopped beating myself up for it. Her advice and support set the foundation for creating a healthy relationship with food and making changes in my nutrition."
              </p>
              <p> - Lyuba </p>
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>
      </Row>
    </PageTemplate>
  );
};

export default IndexPage;
